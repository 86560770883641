import React from 'react'
import ContactTemplate from '../components/pages/contactTemplate/ContactTemplate'
import { graphql } from 'gatsby'

const ContactSk = ({data}) => (
  <ContactTemplate data={data} lang={'sk'}/>
)

export const query = graphql`{
  contact: file(relativePath: {eq: "pages/en/homepage/contact.md"}) {
    childMarkdownRemark {
      frontmatter {
        title
        text_paragraph
      }
    }
  }
}
`

export default ContactSk

import React from 'react'
import Layout from '../../layout'
import ContactForm from '../../contactForm/ContactForm'
import SeoHelmet from '../../seoHelmet'
import './ContactTemplate.scss'

const ContactTemplate = ({lang}) => {
  return (
    <Layout lang={lang}>
        <SeoHelmet
          title={'Kontaktujte nás - Narative.sk'}
        />
      <div className='contact-page'>
        <ContactForm noBackground={true} showCompanyInfo={true}/>
      </div>
    </Layout>
  )

}
export default ContactTemplate

